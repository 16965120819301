<template>
  <ez-loading-block :loading="isLoading">
    <ez-facts
      :items="displayWidgets"
      :active-ids="activeWidgetsIds"
      :max="4"
      @change="onWidgetsUpdate"
      editable
    >
      <template slot="add-button-text">
        {{ $t("dashboard.widgets.add-button-text") }}
      </template>
      <template slot="dialog-title" slot-scope="props">
        {{ $t("dashboard.widgets.dialog-title", props) }}
      </template>
      <template slot="discard-button-text">
        {{ $t("dashboard.widgets.discard-button-text") }}
      </template>
      <template slot="save-button-text">
        {{ $t("dashboard.widgets.save-button-text") }}
      </template>
    </ez-facts>
  </ez-loading-block>
</template>

<script>
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import ezFacts from '@dmant/ez-ui-components/src/components/facts';
import { createNamespacedHelpers, mapState } from 'vuex';
import { LOAD_WIDGETS, CHANGE_WIDGETS } from '@/store/dashboard/actions';
import { GET_WIDGETS, GET_USER_WIDGETS } from '@/store/dashboard/getters';
import ezLoadingBlock from '@/components/ezLoadingBlock.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard');

export default {
  name: 'EzfWidgets',
  components: {
    ezFacts,
    ezLoadingBlock,
  },

  props: {
    demo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      isCoastTypes: [
        'customer_value',
        'total_revenue',
        'recurring_income',
        'lead_value',
        'users_value',
      ],
      isValueTypes: [
        'unique_users',
        'total_sales',
        'total_leads',
      ],
    };
  },

  computed: {
    ...mapState(['currency']),
    ...mapGetters({
      getWidgets: GET_WIDGETS,
      getUserWidgets: GET_USER_WIDGETS,
    }),
    displayWidgets() {
      const existsImages = this.images.keys();
      return this.getWidgets.map((item, index) => {
        const {
          id, type, name, value,
        } = item;
        const userWidget = this.userWidgetsByWidgetId[id];
        const key = type.toLowerCase();
        const image = `./${key}.svg`;
        const url = existsImages.includes(image) ? this.images(image) : undefined;
        const i18nPath = `dashboard.widgets.types.${type}`;
        return {
          id: index,
          icon: url ? undefined : 'eye',
          url,
          text: this.$te(i18nPath) ? this.$t(i18nPath) : name,
          header: (this.isCoastTypes.includes(type))
            ? this.formatCost(get(userWidget, 'value', value))
            : this.formatNumber(get(userWidget, 'value', value)),
          'widget-id': id,
          'user-widget-id': get(userWidget, 'id', null),
        };
      });
    },
    userWidgetsByWidgetId() {
      return keyBy(this.getUserWidgets, 'widget-id');
    },
    activeWidgetsIds() {
      return this.getUserWidgets.reduce((accum, userWidget) => {
        const widget = this.displayWidgets.find((item) => item['widget-id'] === userWidget['widget-id']);
        if (widget) accum.push(widget.id);
        return accum;
      }, []);
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    ...mapActions({
      loadWidgets: LOAD_WIDGETS,
      changeWidgets: CHANGE_WIDGETS,
    }),

    async loadData() {
      this.isLoading = true;
      this.images = require.context('@/assets/stats/dashboard-widgets', false, /\.svg$/);
      await this.loadWidgets(this.demo);
      this.isLoading = false;
    },

    async onWidgetsUpdate({ activeFacts }) {
      // console.log('widgets event', activeFacts, this.getUserWidgets);
      if (this.demo) return;
      const attach = activeFacts
        .filter((item) => item['user-widget-id'] === null)
        .map((item) => item['widget-id']);

      const activeFactsWidgetsIds = activeFacts.map((item) => item['widget-id']);
      const detach = this.getUserWidgets
        .filter((item) => !activeFactsWidgetsIds.includes(item['widget-id']))
        .map((item) => item['widget-id']);

      const change = {
        attach: attach.length ? attach : null,
        detach: detach.length ? detach : null,
        order: activeFactsWidgetsIds.length ? activeFactsWidgetsIds : null,
      };
      await this.changeWidgets(change);
    },

    formatValue(value) {
      return value.toString().replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
    },

    formatNumber(value) {
      return this.formatValue(Math.round(value));
    },

    formatCost(value) {
      const formated = this.formatValue(value.toFixed(2));
      return `${formated}\u00A0${this.currency.symbol}`;
    },

  },
};
</script>
