<script>
import { createNamespacedHelpers } from 'vuex';
import { LOAD_CHART_DATA } from '@/store/dashboard/actions';
import { GET_CHART_DATA } from '@/store/dashboard/getters';
import EzfChart from '@/components/chart/chart.vue';
import EzfGroups from '@/components/chart/groups.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard');

const fields = [
  'uniqueVisitors',
  'leadGain',
  'leads',
  'revenue',
  'visitorValue',
  'customerValue',
];

const EndPoints = {
  uniqueVisitors: 'unique-visitors',
  leadGain: 'lead-gain',
  leads: 'total-leads',
  revenue: 'total-revenue',
  visitorValue: 'visitor-value',
  customerValue: 'customer-value',
};

export default {
  name: 'EzfTotalChart',
  components: {
    EzfChart,
    EzfGroups,
  },

  props: {
    demo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      tabs: [],
      part: 'week',
      chartData: [],
    };
  },

  watch: {
    activeTab() {
      this.loadData();
    },
    part() {
      this.chartData = this.getChartData(this.part);
    },
  },

  computed: {
    ...mapGetters({ getChartData: GET_CHART_DATA }),

    activeTab() {
      const active = this.tabs.find((tab) => tab.active);
      return active || null;
    },
    chartOptions() {
      return {
        tooltip: {
          // trigger: 'axis',
          // position(point, params, dom, rect, size) {
          // return [point[0] - size.contentSize[0] / 2, '10%'];
          // return [point[0] - size.contentSize[0] / 2, point[1] - size.contentSize[1] - 5];
          // },
          trigger: 'item',
          position: 'top',
          formatter: `<span>${this.activeTab.name}</span><br/><span>{c0}</span>`,
        },
        yAxis: {
          axisPointer: {
            snap: false,
          },
        },
        series: [{
          smooth: true,
        }],
        color: ['#3C82CF'],
      };
    },
  },

  created() {
    this.tabs = this.getTabs();
  },

  methods: {
    ...mapActions({
      loadChartData: LOAD_CHART_DATA,
    }),

    async loadData() {
      this.isLoading = true;
      const param = {
        endPoint: EndPoints[this.activeTab.id],
        demo: this.demo,
      };
      await this.loadChartData(param);
      this.chartData = this.getChartData(this.part);
      this.isLoading = false;
    },

    getTabs() {
      return fields.map((field, index) => ({
        id: field,
        name: this.$t(`charts.tabs_label.${field}`),
        description: this.$t(`charts.tabs_hint.${field}`),
        active: index === 0,
      }));
    },

    changeTabs({ id }) {
      this.tabs.map((tab) => {
        tab.active = tab.id === id;
      });
    },

  },
};
</script>

<template>
  <ez-box>
    <template slot="body" class="g">
      <div class="graphs is-inbox graphs--dashboard">
        <ez-row class="mb-4">
          <ez-col>
            <div class="graphs__header">
              <ez-tabs
                :options="tabs"
                solid
                size="big"
                direction="full"
                @click="changeTabs"
              />
            </div>
          </ez-col>
        </ez-row>

        <ez-row>
          <ez-col>
            <ezf-chart
              :options="chartOptions"
              :data="chartData"
              :loading="isLoading"
            >
              <ezf-groups
                v-model="part"
                :class="$style['chart-footer']"
                slot="footer"
              />
            </ezf-chart>
          </ez-col>
        </ez-row>
      </div>
    </template>
  </ez-box>
</template>

<style lang="scss" module>
.chart-footer {
  margin: 0 20px;
}
</style>
