<script>
import { createNamespacedHelpers } from 'vuex';
import Intro from '@/components/Intro';
import EzEmptyPage from '@dmant/ez-ui-components/src/components/empty-page';
import * as headerMutations from '@/store/header/mutations';
import { LOAD_ALL_COUNT } from '@/store/funnels/actions';
import { ALL_FUNNELS_COUNT } from '@/store/funnels/getters';

import ezLoadingBlock from '@/components/ezLoadingBlock.vue';
import EzfWidgets from './widgets.vue';
import EzfTotalChart from './totalChart.vue';
import EzfTotalGeolocation from './totalGeolocation.vue';

const { mapMutations: headerMapMutations } = createNamespacedHelpers('header');
const { mapActions, mapGetters } = createNamespacedHelpers('funnels');

export default {
  name: 'EzfDashboard',
  components: {
    Intro,
    EzfWidgets,
    EzfTotalChart,
    EzfTotalGeolocation,
    ezLoadingBlock,
    EzEmptyPage,
  },

  data() {
    return {
      isLoading: false,
      demoHeader: true,
    };
  },

  computed: {
    ...mapGetters({ count: ALL_FUNNELS_COUNT }),
    isDemo() {
      return !this.count;
    },
  },

  created() {
    this[headerMutations.SET_HEADER_TITLE]([{ name: 'navbar.dashboard' }]);
    // this[headerMutations.SET_HEADER_TABS_VISIBILITY](false);
    this[headerMutations.SET_ACTIVE_NAV_LINK]('dashboard');
    // this[headerMutations.SET_HEADER_CREATE_FUNNEL_VISIBILITY](true);
    this.isFunnelsExist();
  },

  methods: {
    ...mapActions({ loadAllCount: LOAD_ALL_COUNT }),
    ...headerMapMutations([
      `${headerMutations.SET_HEADER_TITLE}`,
      // `${headerMutations.SET_HEADER_TABS_VISIBILITY}`,
      `${headerMutations.SET_ACTIVE_NAV_LINK}`,
      // `${headerMutations.SET_HEADER_CREATE_FUNNEL_VISIBILITY}`
    ]),
    async isFunnelsExist() {
      this.isLoading = true;
      await this.loadAllCount();
      this.isLoading = false;
    },
    hideDemoHeader() {
      this.demoHeader = false;
    },
  },
};
</script>

<template>
  <ez-content>
    <ez-main>
      <ez-container>
        <ez-loading-block :loading="isLoading">
          <ez-row v-if="isDemo && demoHeader" class="mb-4">
            <ez-col>
              <ez-empty-page class="empty-demo">
                <slot name="title" slot="title">
                  {{ $t("dashboard.demo_header") }}
                </slot>
                <slot name="description" slot="description">
                  <span class="empty-demo__desc">{{
                    $t("dashboard.demo_sub_header")
                  }}</span>
                  <ez-button-group
                    justify="center"
                    inside-offsets="medium"
                    class="mt-4"
                  >
                    <ez-button
                      type="primary"
                      data-test="quick-start"
                      @click="$emit('openQuickStart')"
                    >
                      {{ $t("funnels.quickStartButton") }}
                    </ez-button>
                  </ez-button-group>
                </slot>
              </ez-empty-page>
            </ez-col>
          </ez-row>

          <ez-row class="mb-4">
            <ez-col>
              <ezf-widgets :demo="isDemo" />
            </ez-col>
          </ez-row>

          <ez-row class="mb-4">
            <ez-col>
              <Ezf-total-chart :demo="isDemo" />
            </ez-col>
          </ez-row>

          <ez-row>
            <ez-col>
              <ezf-total-geolocation :demo="isDemo" />
            </ez-col>
          </ez-row>
        </ez-loading-block>
      </ez-container>
      <Intro />
    </ez-main>
  </ez-content>
</template>

<style lang="scss" scoped>
.demo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-demo {
  margin: 0 auto 30px;
  border-bottom: 1px solid #e1e4e7;
  padding-bottom: 10px;
  max-width: 688px;

  &__desc {
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
