<script>
import { createNamespacedHelpers } from 'vuex';
import { LOAD_TOTAL_GEOLOCATION } from '@/store/dashboard/actions';
import { GET_TOTAL_GEOLOCATION } from '@/store/dashboard/getters';
import ezLoadingBlock from '@/components/ezLoadingBlock.vue';
import ezfTable from '@/components/table/table';
import geolocationMixin from '@/components/table/mixins/geolocation';

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard');

export default {
  name: 'EzfTotalGeolocation',
  mixins: [geolocationMixin],
  components: {
    ezLoadingBlock,
    ezfTable,
  },

  props: {
    demo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({ getGeolocation: GET_TOTAL_GEOLOCATION }),
  },

  created() {
    this.loadGeolocationData();
  },

  methods: {
    ...mapActions({ loadGeolocation: LOAD_TOTAL_GEOLOCATION }),

    async loadGeolocationData() {
      this.beforeLoadGeolocationData();
      await this.loadGeolocation(this.demo);
      this.afterLoadGeolocationData();
    },
  },
};
</script>

<template>
  <ez-loading-block :loading="isGeolocatonLoading">
    <!-- <ez-box>
      <template slot="body"> -->
    <ezf-table :meta="meta" :data="getTableData" />
    <!-- </template>
    </ez-box> -->
  </ez-loading-block>
</template>
