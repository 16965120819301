<template>
  <transition
    v-if="isOpen"
    name="quick-start-welcome"
    mode="out-in"
  >
    <div v-if="isOpen" :class="$style['container']">
      <div :class="$style['dialog']">
        <img
          :class="$style['poster']"
          src="../assets/intro/Welcome.svg"
        >

        <div :class="$style['title']">
          {{ $t('_intro_welcome_header') }}
        </div>

        <div :class="$style['description']">
          {{ $t('_intro_welcome_subheader') }}
        </div>

        <div :class="$style['steps']">
          <div :class="$style['step']">
            <img src="../assets/intro/CollectLeads.svg">
            <div :class="$style['step-title']">
              {{ $t('Collect leads') }}
            </div>
          </div>
          <div :class="$style['step']">
            <img src="../assets/intro/GenerateSales.svg">
            <div :class="$style['step-title']">
              {{ $t('Generate sales') }}
            </div>
          </div>
          <div :class="$style['step']">
            <img src="../assets/intro/HostWebinars.svg">
            <div :class="$style['step-title']">
              {{ $t('Host webinars') }}
            </div>
          </div>
          <div :class="$style['step']">
            <img src="../assets/intro/MemberArea.svg">
            <div :class="$style['step-title']">
              {{ $t('Memberarea') }}
            </div>
          </div>
        </div>

        <div :class="$style['buttons']">
          <ez-button
            type="primary"
            size="large"
            @click="startTour"
          >
            {{ $t('Start the wizard') }}
          </ez-button>

          <div :class="$style['skip']" @click="onSkip">
            {{ $t('Skip') }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import introJs from 'intro.js/src/index';
import { updateAccount } from '@/services/apiUser';

export default {
  name: 'Intro',
  data: () => ({
    isOpen: false,
    serviceDropdownComponent: null,
    userMenuComponent: null,
  }),
  computed: {
    ...mapState(['mainUser']),
    hasVideos() {
      if (this.$quickStartVideo) {
        return this.$store.getters[`${this.$quickStartVideo.moduleName}/hasVideos`];
      }
      return false;
    },
    steps() {
      const basicSteps = [
        {
          title: this.$t('_intro_1_title'),
          element: '.header-inner__logotype .logotype',
          intro: this.$t('_intro_1_desc'),
        },
        {
          title: this.$t('_intro_2_title'),
          beforeEnter: () => {
            this.toggleServicesDropdown(false);
          },
          element: '.header-inner__nav',
          intro: this.$t('_intro_2_desc'),
        },
        {
          title: this.$t('_intro_3_title'),
          beforeEnter: async () => {
            this.toggleServicesDropdown(true);
            this.toggleUserMenuDropdown(false);
            return new Promise((resolve) => {
              setTimeout(() => {resolve()}, 100)
            });
          },
          element: () => document.querySelector('.dropdown-services__popover'),
          intro: this.$t('_intro_3_desc'),
        },
        {
          title: this.$t('_intro_4_title'),
          beforeEnter: async () => {
            this.toggleServicesDropdown(false);
            this.toggleUserMenuDropdown(true);
            return new Promise((resolve) => {
              setTimeout(() => {resolve()}, 100)
            });
          },
          element: () => document.querySelector('.dropdown-user__popover'),
          intro: this.$t('_intro_4_desc'),
        },
      ];

      if (this.hasVideos) {
        basicSteps.push({
          title: this.$t('_intro_5_title'),
          beforeEnter: () => {
            this.toggleUserMenuDropdown(false);
          },
          element: document.querySelector('.quick-start-button'),
          intro: this.$t('_intro_5_desc'),
          position: 'left',
        })
      }
      return basicSteps
    },
  },
  async mounted() {
    const { welcome } = this.mainUser.attributes;
    this.isOpen = !welcome || this.$route.query.ezf === 'welcome' || document.cookie.match(/\bezf-welcome=true\b/);
    if (this.isOpen && this.$quickStartVideo) {
      this.$quickStartVideo.toggleShow(false);
    }
    if (this.isOpen) {
      this.serviceDropdownComponent = this.getComponentByTag(this.$root, 'ez-dropdown-services');
      this.userMenuComponent = this.getComponentByTag(this.$root, 'ez-dropdown-user');

      const { 'legacy-id': legacyId } = this.mainUser.attributes;
      await updateAccount(legacyId, { welcome: true })
    }
  },
  methods: {
    async startTour() {
      this.isOpen = false;

      const tour = introJs().setOptions({
        hidePrev: true,
        showBullets: false,
        showStepNumbers: true,
        nextLabel: this.$t('Next'),
        prevLabel: this.$t('Back'),
        doneLabel: this.$t('Done'),
        tooltipClass: 'ez-intro-tooltip',
        steps: this.steps,
      });

      tour.onexit(() => {
        this.toggleServicesDropdown(false);
        this.toggleUserMenuDropdown(false);
      });

      tour.start();
    },
    async onSkip() {
      this.isOpen = false
    },

    toggleServicesDropdown(state) {
      if (this.serviceDropdownComponent) {
        this.serviceDropdownComponent.isOpen = state;
        this.serviceDropdownComponent.autoHide = !state;
      }
    },
    toggleUserMenuDropdown(state) {
      if (this.userMenuComponent) {
        this.userMenuComponent.isOpen = state;
        this.userMenuComponent.autoHide = !state;
      }
    },
    getComponentByTag(component, tag) {
      if (component.$options._componentTag === tag) {
        return component;
      }

      for (let child of component.$children) {
        const found = this.getComponentByTag(child, tag);
        if (found) {
          return found;
        }
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" src="intro.js/src/styles/introjs.scss"></style>
<style lang="scss" module>
.container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#25252B, 0.6);
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 40px;
  padding: 40px;
  width: 860px;
  max-width: 100%;
  background-color: #fff;
}

.poster {
  margin-bottom: 24px;
  width: 470px;
}

.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: $color-gray-800;
}

.description {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $color-gray-800;
}

.steps {
  display: flex;
  align-items: flex-end;
  margin: 70px -25px 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  text-align: center;
}

.step-title {
  margin-top: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
}

.skip {
  margin-top: 8px;
  padding: 8px;
  min-width: 124px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
</style>

<style lang="scss">
.introjs-helperLayer {
  box-shadow: 0 0 0 5000px rgba(#25252B, 0.6) !important;
}

.ez-intro-tooltip {
  .introjs-tooltip {
    max-width: 320px;
  }

  .introjs-tooltip-header {
    padding: 16px 16px 0;
  }

  .introjs-tooltip-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #5D6B7E;
  }

  .introjs-skipbutton {
    position: absolute;
    right: 8px;
    top: 4px;
    font-size: 25px;
    color: $color-gray-300;
    transition: color 0.2s;

    &:hover {
      color: #8095AA;
    }
  }

  .introjs-tooltiptext {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    color: #5D6B7E;
  }

  .introjs-prevbutton,
  .introjs-nextbutton {
    border-radius: 4px;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 14px;
    text-shadow: none !important;
    transition: color 0.2s, background-color 0.2s, border-color 0.2s;
    cursor: pointer;

    &:focus:not(.introjs-disabled) {
      outline: none;
      box-shadow: 0 0 0 3px rgba(#3D82CF, 0.3);
    }
  }

  .introjs-prevbutton {
    order: 1;
    border: 1px solid #DCE1E5;
    border-radius: 4px;
    text-shadow: none !important;
    color: #5D6B7E;
    background-color: #fff;

    &:hover {
      border-color: #5D6B7E;
      color: #fff;
      background-color: #5D6B7E;
    }

    &.introjs-disabled {
      opacity: 0.8;
      cursor: not-allowed;

      &:focus,
      &:hover {
        border-color: #DCE1E5;
        color: #5D6B7E;
        background-color: #fff;
      }
    }
  }

  .introjs-nextbutton {
    order: 3;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    text-shadow: none !important;
    color: #FFF;
    background-color: #3D82CF;
    transition: color 0.2s, background-color 0.2s, border-color 0.2s;
    cursor: pointer;

    &:hover,
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: #fff;
      background-color: #518fd4;
    }
  }

  .introjs-tooltipbuttons {
    display: flex;
    justify-content: space-between;
    border-top: none;
    padding: 8px 16px 16px;

    &::after {
      display: none;
    }
  }

  .introjs-helperNumberLayer {
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;
    padding: 8px 16px;
    width: 100%;
    min-width: 32px;
    font-size: 12px;
    white-space: nowrap;
    color: #8095AA;
  }
}

// transitions
.quick-start-welcome-enter-active,
.quick-start-welcome-leave-active {
  opacity: 0;
  transition: all 0.2s;
}
</style>
